import React from "react";

export const ChevronUp = ({height = 10, width = 16, className, color}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      fill='none'
      color={color}
      viewBox='0 0 16 10'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 9L8 1L15 9'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
