import React from "react";

export const AuditBGSVG = ({className, onClick}) => (
  <svg
    className={className}
    onClick={onClick}
    width='1438'
    height='605'
    viewBox='0 0 1438 605'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M-1 110.697L1439 0V492.148L-1 605V110.697Z' fill='#FFF2F2' />
  </svg>
);
