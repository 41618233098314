import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import SanityRichText from "../../../commons/SanityRichText";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const AuditExpertSection = ({data}) => {
  const {
    expertsHeading,
    _rawExpertsDescription: expertsDescription,
    expertsCTAText,
    expertsGIF,
  } = data;

  return (
    <div className={styler.get("auditExpertWrapper")}>
      <div className={styler.get("auditExpertTextSection")}>
        <p className={styler.get("auditExpertTitle")}>{expertsHeading}</p>
        <div className={styler.get("auditExpertDescription")}>
          <SanityRichText blocks={expertsDescription} />
        </div>
        <OutboundLink
          href='#auditForm'
          target='_self'
          className={styler.get("expertsCTA")}
        >
          {expertsCTAText}
        </OutboundLink>
      </div>
      <div className={styler.get("expertsCardSection")}>
        <GatsbyImage
          className={styler.get("expertImageGif")}
          image={expertsGIF?.image?.asset?.gatsbyImageData}
        />
      </div>
    </div>
  );
};

export default AuditExpertSection;
