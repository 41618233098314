import {StyleExtractor, isFunction, isValidString} from "@kubric/utils";
import DeviceDetector from "device-detector-js";
import {GatsbyImage} from "gatsby-plugin-image";
import * as React from "react";
import Marquee from "react-fast-marquee";

import {
  auditFormSubmission,
  auditQuizSubmission,
} from "../../../../utils/audit";
import {Dropdown} from "../../../Dropdown";
import {MMReviewPanel} from "../../../commons/MMCTA";
import SanityRichText from "../../../commons/SanityRichText";
import {AlertCircle} from "../../../icons/AlertCircle";
import {ChevronUp} from "../../../icons/ChevronUp";
import * as styles from "./styles.module.scss";

const deviceDetector = new DeviceDetector();

const styler = new StyleExtractor(styles);

const FormStateTypes = {
  FORM: "form",
  QUIZ: "quiz",
  PROBLEM: "problem",
  SUCCESS_FORM: "successForm",
  SUCCESS_QUIZ: "successQuiz",
};

const ErrorField = ({errorMessage}) => {
  return (
    <div className={styler.get("errorContainer")}>
      <AlertCircle /> <span>{errorMessage}</span>
    </div>
  );
};

const AuditForm = ({
  auditBookContent,
  auditFormThanksSection,
  auditQuizProblem,
  auditQuizSection,
  auditQuizThanksSection,
}) => {
  const [formState, setFormState] = React.useState(FormStateTypes.FORM);
  const [questionNo, setQuestionNo] = React.useState(0);

  const {
    auditFormCTA,
    auditFormFullName,
    auditFormworkEmail,
    auditFormTitle,
    auditFormStoreURL,
    auditFormMonthlyTrafficLabel,
    auditFormMonthlyTraffic,
    auditFormFullNamePlaceHolder,
    auditFormStoreURLPlaceholder,
    auditFormworkEmailPlaceholder,
    auditFormFullNameError,
    auditFormStoreURLError,
    auditFormworkError,
  } = auditBookContent;

  const {auditQuizBeginCTA, auditThanksLaterCTA, auditThanksMessage} =
    auditFormThanksSection;

  const {
    auditQuiz: auditQuizQuestions,
    auditQuizHeader,
    auditQuizNextCTA,
  } = auditQuizSection;

  const {
    auditQuiz: auditProblemQuestion,
    auditQuizHeader: auditProblemHeader,
    auditQuizNextCTA: auditProblemNextCTA,
  } = auditQuizProblem;

  const [showOthersInput, setShowOthersInsput] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [formData, setFormData] = React.useState({
    userName: "",
    email: "",
    traffic: auditFormMonthlyTraffic[0],
    website: "",
  });

  const [quizResponses, setQuizResponses] = React.useState({
    question1: "",
    question2: "",
    question3: "",
    problemQ: "",
    answer1: "",
    answer2: "",
    answer3: "",
    problem: "",
  });

  let device = "desktop";
  try {
    device =
      deviceDetector.parse(window.navigator.userAgent).device.type ?? "desktop";
  } catch {
    device = "desktop";
  }

  return (
    <div className={styler.get("auditFormWrapper")}>
      {formState === FormStateTypes.FORM && (
        <div className={styler.get("formSection")}>
          <p className={styler.get("auditFormTitle")}>{auditFormTitle}</p>
          <div className={styler.get("auditForm")}>
            <div className={styler.get("formInputContainer")}>
              <p>{auditFormFullName}</p>
              <input
                onChange={(e) =>
                  setFormData({...formData, userName: e.target.value})
                }
                className={styler.get("inputField")}
                placeholder={auditFormFullNamePlaceHolder}
              />
              {error && !isValidString(formData.userName) && (
                <ErrorField errorMessage={auditFormFullNameError} />
              )}
            </div>
            <div className={styler.get("multiFormInputContainer")}>
              <div className={styler.get("formContainer")}>
                <p>{auditFormworkEmail}</p>
                <input
                  onChange={(e) =>
                    setFormData({...formData, email: e.target.value})
                  }
                  className={styler.get("inputField")}
                  placeholder={auditFormworkEmailPlaceholder}
                />
                {error && !isValidString(formData.email) && (
                  <ErrorField errorMessage={auditFormworkError} />
                )}
              </div>
              <div className={styler.get("formContainer")}>
                <p>{auditFormMonthlyTrafficLabel}</p>
                <Dropdown
                  options={auditFormMonthlyTraffic}
                  onSelect={(value) =>
                    setFormData({...formData, traffic: value})
                  }
                />
              </div>
            </div>
            <div className={styler.get("formInputContainer")}>
              <p>{auditFormStoreURL}</p>
              <input
                onChange={(e) =>
                  setFormData({...formData, website: e.target.value})
                }
                className={styler.get("inputField")}
                placeholder={auditFormStoreURLPlaceholder}
              />
              {error && !isValidString(formData.website) && (
                <ErrorField errorMessage={auditFormStoreURLError} />
              )}
            </div>
          </div>
          <button
            onClick={() => {
              if (
                Object.values(formData).some((data) => !isValidString(data))
              ) {
                setError(true);
              } else {
                if (isFunction(window?.gtag)) {
                  window?.gtag("event", "store_audit_submission_successful", {
                    name: formData.userName,
                    email: formData.email,
                    traffic: formData.traffic,
                    url: formData.website,
                  });
                }
                auditFormSubmission({...formData, device}).then(() =>
                  setFormState(FormStateTypes.SUCCESS_FORM)
                );
              }
            }}
            type='button'
            id='store-audit-form-submitted'
            className={styler.get("auditFormCTA")}
          >
            {auditFormCTA}
          </button>
        </div>
      )}
      {formState === FormStateTypes.SUCCESS_FORM && (
        <div className={styler.get("formSuccessState")}>
          <div className={styler.get("formThanksSection")}>
            <img
              className={styler.get("successFormImage")}
              src='https://media.kubric.io/api/assetlib/665bfc3f-0341-45d4-8761-3e53ed69a79e.gif'
            />
            <p>{auditThanksMessage}</p>
          </div>
          <div className={styler.get("formSuccessControls")}>
            <button
              type='button'
              onClick={() => setFormState(FormStateTypes.SUCCESS_QUIZ)}
              className={styler.get(["formSuccessCTA", "formEndCTA"])}
            >
              {auditThanksLaterCTA}
            </button>
            <button
              type='button'
              onClick={() => setFormState(FormStateTypes.QUIZ)}
              className={styler.get(["formSuccessCTA", "quizStartCTA"])}
            >
              {auditQuizBeginCTA}
            </button>
          </div>
        </div>
      )}
      {formState === FormStateTypes.QUIZ && (
        <div className={styler.get("formQuizLayout")}>
          <div className={styler.get("quizHeader")}>
            <p className={styler.get("quizHeaderText")}>{auditQuizHeader}</p>
          </div>
          <div className={styler.get("quizQuestionDisplay")}>
            <p>
              {questionNo + 1} {auditQuizQuestions[questionNo].auditQuestion}
            </p>
            <div className={styler.get("optionsContainer")}>
              {auditQuizQuestions[questionNo].auditOptions.map(
                ({auditAnswerOptionAnswer, auditAnswerOptionValue}) => {
                  return (
                    <span
                      className={styler.get("option")}
                      onClick={() => {
                        setQuizResponses({
                          ...quizResponses,
                          [`answer${questionNo + 1}`]: auditAnswerOptionAnswer,
                          [`question${questionNo + 1}`]:
                            auditQuizQuestions[questionNo].auditQuestion,
                        });
                        if (questionNo < auditQuizQuestions.length - 1) {
                          setQuestionNo((prevVal) => prevVal + 1);
                        } else {
                          setFormState(FormStateTypes.PROBLEM);
                        }
                      }}
                    >
                      {auditAnswerOptionValue}. {auditAnswerOptionAnswer}
                    </span>
                  );
                }
              )}
            </div>
          </div>
          <div className={styler.get("quizControls")}>
            <button
              type='button'
              className={styler.get(["formSuccessCTA", "quizBackCTA"])}
              onClick={() => {
                if (questionNo !== 0) {
                  setQuestionNo((prevVal) => prevVal - 1);
                }
              }}
            >
              <ChevronUp color='#808693' />
            </button>
            <button
              type='button'
              onClick={() => {
                if (questionNo !== auditQuizQuestions.length - 1) {
                  setQuestionNo((prevVal) => prevVal + 1);
                } else {
                  setFormState(FormStateTypes.PROBLEM);
                }
              }}
              className={styler.get(["formSuccessCTA", "quizNextCTA"])}
            >
              <ChevronUp
                className={styler.get("quizNextChevron")}
                color='#ffffff'
              />
              {auditQuizNextCTA}
            </button>
          </div>
        </div>
      )}
      {formState === FormStateTypes.PROBLEM && (
        <div className={styler.get("formQuizLayout")}>
          <div className={styler.get("quizHeader")}>
            <p className={styler.get("quizHeaderText")}>{auditProblemHeader}</p>
          </div>
          <div
            className={styler.get(["quizQuestionDisplay", "problemsDisplay"])}
          >
            {auditProblemQuestion[0].auditOptions.map(
              ({auditAnswerOptionAnswer, auditAnswerOptionValue}) => {
                if (showOthersInput && auditAnswerOptionAnswer === "others") {
                  return (
                    <input
                      onChange={(e) =>
                        setQuizResponses({
                          ...quizResponses,
                          problem: e.target.value,
                        })
                      }
                      className={styler.get("othersInputField")}
                      placeholder='Type your answer here'
                      type='text'
                    />
                  );
                }
                return (
                  <div
                    onClick={() => {
                      if (auditAnswerOptionAnswer === "others") {
                        setShowOthersInsput(true);
                      } else {
                        setQuizResponses({
                          ...quizResponses,
                          problem: auditAnswerOptionAnswer,
                          problemQ: auditProblemHeader,
                        });
                      }
                    }}
                    className={styler.get([
                      "problemOption",
                      quizResponses?.problem === auditAnswerOptionAnswer &&
                        "selectedOption",
                    ])}
                  >
                    {auditAnswerOptionValue}. {auditAnswerOptionAnswer}
                  </div>
                );
              }
            )}
          </div>
          <div className={styler.get("quizControls")}>
            <button
              type='button'
              className={styler.get(["formSuccessCTA", "quizBackCTA"])}
              onClick={() => {
                setFormState(FormStateTypes.QUIZ);
              }}
            >
              <ChevronUp color='#808693' />
            </button>
            <button
              type='button'
              onClick={() => {
                auditQuizSubmission({
                  ...quizResponses,
                  ...formData,
                  device,
                }).then(() => setFormState(FormStateTypes.SUCCESS_QUIZ));
              }}
              className={styler.get(["formSuccessCTA", "quizNextCTA"])}
            >
              {auditProblemNextCTA}
            </button>
          </div>
        </div>
      )}
      {formState === FormStateTypes.SUCCESS_QUIZ && (
        <div className={styler.get("successQuizLayout")}>
          <div className={styler.get("successGifBG")}>
            <img
              className={styler.get("successGifMoney")}
              src='https://media.kubric.io/api/assetlib/6fbd5eb3-2056-4aea-967e-a23eb0532164.gif'
            />
            <img
              className={styler.get("successOverlay")}
              src='https://media.kubric.io/api/assetlib/665bfc3f-0341-45d4-8761-3e53ed69a79e.gif'
            />
          </div>
          <p className={styler.get("successQuizText")}>
            {auditQuizThanksSection.auditThanksMessage}
          </p>
        </div>
      )}
    </div>
  );
};

const AuditBookSection = ({data, isMobile = false}) => {
  const {
    heroAuditSection,
    auditBookContent,
    auditFormThanksSection,
    auditQuizProblem,
    auditQuizSection,
    auditQuizThanksSection,
  } = data;

  const auditQuizData = {
    auditBookContent,
    auditFormThanksSection,
    auditQuizProblem,
    auditQuizSection,
    auditQuizThanksSection,
  };
  return (
    <div className={styler.get("auditSectionWrapper")}>
      {!isMobile && (
        <div className={styler.get("auditSectionText")}>
          <h2 className={styler.get("auditSectionTitle")}>
            {heroAuditSection.auditHeroTitle}
          </h2>
          <div className={styler.get("auditSectionDescription")}>
            <SanityRichText
              blocks={heroAuditSection._rawAuditHeroDescription}
            />
          </div>
          <div className={styler.get("auditSocialProofContainer")}>
            {heroAuditSection.auditSocialProofs.map((image) => {
              return (
                <div className={styler.get(["socialProof"])}>
                  <GatsbyImage
                    alt={image?.alt}
                    image={image?.image?.asset?.gatsbyImageData}
                    className={styler.get([image?.alt ?? ""])}
                    objectFit='contain'
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className={styler.get("auditSectionForm")}>
        {isMobile && (
          <>
            <h2 className={styler.get("auditSectionTitle")}>
              {heroAuditSection.auditHeroTitle}
            </h2>
            <Marquee
              speed={80}
              className={styler.get("mobileSocialProofContainer")}
            >
              {heroAuditSection.auditSocialProofs.map((image) => {
                return (
                  <div className={styler.get(["socialProofMobile"])}>
                    <GatsbyImage
                      alt={image?.alt}
                      image={image?.image?.asset?.gatsbyImageData}
                      className={styler.get([image?.alt ?? ""])}
                      objectFit='contain'
                    />
                  </div>
                );
              })}
            </Marquee>
          </>
        )}
        <AuditForm {...auditQuizData} />
        <div className={styler.get("reviewContainer")}>
          <MMReviewPanel showHighlighted />
        </div>
      </div>
    </div>
  );
};

export default AuditBookSection;
